import axios from "axios";
import authHeader from "./auth-header";
class Tracking {
  insert(dataTracking) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios.post(
          "/management/tracking",
          dataTracking,
          {
            headers: authHeader(),
          }
        );
        return resolve(response.data);
      } catch (error) {
        return reject(error.response.data);
      }
    });
  }

  findAll({ customer, date, page, limit }) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios.get(
          `/management/tracking?customer=${customer}&date=${date}&page=${page}&limit=${limit}`,
          {
            headers: authHeader(),
          }
        );
        return resolve(response.data);
      } catch (error) {
        return reject(error.response.data);
      }
    });
  }

  findCustomerAll({ phoneNumber, page, limit }) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios.get(
          `/management/orders-customer?phoneNumber=${phoneNumber}&page=${page}&limit=${limit}`
        );
        return resolve(response.data);
      } catch (error) {
        return reject(error.response.data);
      }
    });
  }

  update(trackingList) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.patch(
          "/management/tracking",
          trackingList,
          {
            headers: authHeader(),
          }
        );
        return resolve(data);
      } catch (error) {
        return reject(error.response.data);
      }
    });
  }

  delete(order_id) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.delete("/management/tracking", {
          data: { order_id },
          headers: authHeader(),
        });
        return resolve(data);
      } catch (error) {
        return reject(error.response.data);
      }
    });
  }
}
export default new Tracking();
