<template>
  <div id="add-tracking">
    <Navigation />
    <v-main class="content mb-9">
      <v-container fluid>
        <v-card style="border-radius: 2px" color="#383E45">
          <v-container fluid class="py-5 px-6">
            <HeaderPage title="เพิ่ม Tracking Number" />
            <v-form
              v-on:submit.prevent="addTrackingOrder()"
              ref="form"
              v-model="valid"
              lazy-validation
            >
              <v-col cols="12" class="px-0">
                <v-row>
                  <v-col cols="12" md="8" xl="6" v-if="userPhoneStatus === 1">
                    <label>เบอร์โทรศัพท์ลูกค้าเดิม</label>
                    <div class="d-flex">
                      <v-autocomplete
                        type="number"
                        class="phone-user-input mt-2"
                        solo
                        dense
                        :items="customerItems"
                        item-text="phone"
                        return-object
                        @change="selectOldCustomer()"
                        v-model="oldCustomerObj"
                        :rules="oldPhoneRule"
                        required
                      ></v-autocomplete>
                      <v-btn
                        @click.prevent="changeToAddNewCustomer"
                        class="mt-2 ml-2"
                        color="blue"
                        height="38px"
                      >
                        <v-icon left>mdi-plus</v-icon>
                        เพิ่มลูกค้าใหม่
                      </v-btn>
                    </div>
                  </v-col>
                  <v-col cols="12" md="8" xl="6" v-if="userPhoneStatus === 2">
                    <label>เบอร์โทรศัพท์ลูกค้าใหม่</label>
                    <div class="d-flex">
                      <v-text-field
                        type="number"
                        class="phone-user-input mt-2"
                        solo
                        dense
                        v-model="dataTracking.phoneNumber"
                        @change="checkRoleCustomer"
                        :rules="newPhoneRule"
                        required
                      ></v-text-field>
                      <v-btn
                        @click.prevent="userPhoneStatus = 1"
                        class="mt-2 ml-2 white--text"
                        color="white"
                        height="38px"
                        outlined
                        >ลูกค้าเดิม</v-btn
                      >
                    </div>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" class="px-0">
                <v-row>
                  <v-col cols="12" md="6" xl="4">
                    <label for="">ชื่อจริง</label>
                    <v-text-field
                      class="mt-2"
                      solo
                      dense
                      v-model="dataTracking.firstName"
                      :rules="firstNameRule"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" xl="4">
                    <label for="">นามสกุล</label>
                    <v-text-field
                      class="mt-2"
                      solo
                      dense
                      v-model="dataTracking.lastName"
                      :rules="lastNameRule"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-col
                cols="12"
                class="px-0 pb-0"
                v-for="(item, index) in dataTracking.orders"
                :key="index"
              >
                <v-row>
                  <v-col cols="12" md="5" xl="4">
                    <label for=""
                      >Tracking Number (รายการที่ {{ index + 1 }})</label
                    >
                    <v-text-field
                      class="mt-2"
                      solo
                      dense
                      v-model="dataTracking.orders[index].trackingNumber"
                      :rules="trackingRule"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="7">
                    <v-row
                      v-for="(product, i) in dataTracking.orders[index]
                        .products"
                      :key="i"
                    >
                      <v-col cols="8" class="pb-0">
                        <label for="">ชื่อสินค้าที่สั่ง</label>
                        <v-autocomplete
                          :rules="productsRule"
                          class="mt-2"
                          solo
                          dense
                          :items="productItems"
                          item-value="id"
                          item-text="product_name"
                          v-model="
                            dataTracking.orders[index].products[i].product_id
                          "
                          required
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="4" class="pb-0">
                        <label for="">จำนวน</label>
                        <v-text-field
                          type="number"
                          solo
                          dense
                          class="mt-2"
                          v-model="
                            dataTracking.orders[index].products[i].quantity
                          "
                          :rules="quantityRule"
                          required
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <div class="mt-2 mb-4">
                      <v-btn
                        v-if="dataTracking.orders[index].products.length > 1"
                        class="mr-3"
                        color="red"
                        small
                        outlined
                        @click.prevent="delProductInput(index)"
                        >ลบ</v-btn
                      >
                      <v-btn
                        color="white"
                        outlined
                        small
                        @click.prevent="addProductInput(index)"
                      >
                        <v-icon left>mdi-plus</v-icon> เพิ่มสินค้าชิ้นที่
                        {{
                          dataTracking.orders[index].products.length + 1
                        }}</v-btn
                      >
                    </div>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" class="pt-2 px-0 text-center">
                <v-btn
                  v-if="dataTracking.orders.length > 1"
                  class="mr-4"
                  color="red"
                  outlined
                  @click.prevent="delTrackingInput"
                  >ลบ</v-btn
                >
                <v-btn color="white" outlined @click.prevent="addTrackingInput"
                  >เพิ่ม Tracking Number</v-btn
                >
              </v-col>
              <v-col cols="12" class="pt-0 px-0">
                <v-row>
                  <v-col cols="12" md="6" xl="4" class="pt-9">
                    <label for="">ชื่อขนส่ง</label>
                    <v-select
                      :items="$store.getters.getLogisticsName"
                      v-model="dataTracking.logisticsName"
                      solo
                      dense
                      class="mt-2"
                      :rules="logisticsRule"
                      required
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="6" xl="4" class="pt-9">
                    <label for="">หมายเหตุ</label>
                    <v-text-field
                      v-model="dataTracking.remark"
                      solo
                      dense
                      class="mt-2"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" xl="10" class="px-0 text-right">
                <v-btn
                  :loading="loading"
                  large
                  color="blue"
                  @click.prevent="addTrackingOrder"
                >
                  <v-icon left>mdi-checkbox-marked-circle-outline</v-icon>
                  บันทึกการเพิ่ม
                </v-btn>
                <v-btn
                  class="ml-6"
                  large
                  outlined
                  color="red"
                  @click.prevent="cancelTrackingOrder()"
                  >ยกเลิก</v-btn
                >
              </v-col>
            </v-form>
          </v-container>
        </v-card>
      </v-container>
    </v-main>
    <FooterAdmin />
  </div>
</template>

<script>
import Navigation from '@/components/Navigation';
import FooterAdmin from '@/components/FooterAdmin';
import HeaderPage from '@/components/HeaderPage';

import ProductService from '@/services/Product.service';
import CustomerService from '@/services/Customer.service';
import TrackingService from '@/services/Tracking.service';

import Swal from 'sweetalert2';

export default {
  name: 'AddTracking',
  components: {
    Navigation,
    FooterAdmin,
    HeaderPage,
  },
  data() {
    return {
      loading: false,
      valid: true,
      oldPhoneRule: [(v) => !!v || 'กรุณาเลือกเบอร์โทรศัพท์ลูกค้า'],
      newPhoneRule: [(v) => !!v || 'กรุณากรอกเบอร์โทรศัพท์ลูกค้า'],
      firstNameRule: [(v) => !!v || 'กรุณากรอกชื่อจริงของลูกค้า'],
      lastNameRule: [(v) => !!v || 'กรุณากรอกนามสกุลของลูกค้า'],
      trackingRule: [(v) => !!v || 'กรุณากรอกหมายเลข Tracking Number'],
      productsRule: [(v) => !!v || 'กรุณาเลือกสินค้า'],
      quantityRule: [(v) => !!v || 'กรุณาระบุจำนวน'],
      logisticsRule: [(v) => !!v || 'กรุณาเลือกขนส่งที่ใช้บริการ'],

      userPhoneStatus: 1,
      customerItems: [],
      productItems: [],
      oldCustomerObj: null,
      dataTracking: {
        phoneNumber: '',
        customer_id: null,
        firstName: '',
        lastName: '',
        logisticsName: '',
        orders: [
          {
            trackingNumber: null,
            products: [{ product_id: null, quantity: 1 }],
          },
        ],
        remark: '',
      },
    };
  },
  created() {
    this.getProducts();
    this.getCustomers();
  },
  methods: {
    async getProducts() {
      try {
        const response = await ProductService.findAll();
        this.productItems = response;
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: error.msg,
          showConfirmButton: false,
          timer: 3000,
        });
      }
    },
    async getCustomers() {
      try {
        const response = await CustomerService.findAll();
        this.customerItems = response;
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: error.msg,
          showConfirmButton: false,
          timer: 3000,
        });
      }
    },
    addTrackingInput() {
      const order = {
        trackingNumber: null,
        products: [{ product_id: null, quantity: 1 }],
      };
      this.dataTracking.orders.push(order);
    },
    addProductInput(index) {
      const product = { product_id: null, quantity: 1 };
      this.dataTracking.orders[index].products.push(product);
    },
    delTrackingInput() {
      this.dataTracking.orders.pop();
    },
    delProductInput(index) {
      this.dataTracking.orders[index].products.pop();
    },
    selectOldCustomer() {
      this.dataTracking.customer_id = this.oldCustomerObj.id;
      this.dataTracking.firstName = this.oldCustomerObj.first_name;
      this.dataTracking.lastName = this.oldCustomerObj.last_name;
      this.dataTracking.phoneNumber = this.oldCustomerObj.phone;
    },
    changeToAddNewCustomer() {
      this.userPhoneStatus = 2;
      this.clearCustomerData();
    },
    checkRoleCustomer() {
      const checkCustomer = this.customerItems.some(
        (data) => data.phone === this.dataTracking.phoneNumber
      );
      if (checkCustomer === true) {
        Swal.fire({
          icon: 'info',
          title: 'มีเบอร์โทรศัพท์นี้ในระบบ',
          showConfirmButton: true,
          confirmButtonColor: '#3085d6',
          timer: 3000,
        });
        this.dataTracking.phoneNumber = '';
        this.userPhoneStatus = 1;
      }
    },
    clearCustomerData() {
      this.dataTracking.customer_id = null;
      this.dataTracking.firstName = '';
      this.dataTracking.lastName = '';
      this.dataTracking.phoneNumber = '';
      this.oldCustomerObj = null;
    },
    clearTrackingOrder() {
      this.dataTracking.orders = [
        { trackingNumber: null, products: [{ product_id: null, quantity: 1 }] },
      ];
      this.dataTracking.logisticsName = '';
      this.dataTracking.remark = '';
    },
    cancelTrackingOrder() {
      this.clearCustomerData();
      this.clearTrackingOrder();
    },
    async addTrackingOrder() {
      this.loading = true;
      if (this.$refs.form.validate()) {
        try {
          const response = await TrackingService.insert(this.dataTracking);
          this.loading = false;
          Swal.fire({
            position: 'bottom-end',
            toast: true,
            icon: 'success',
            title: response.msg,
            showConfirmButton: false,
            timer: 3000,
          });
          this.clearCustomerData();
          this.clearTrackingOrder();
          this.userPhoneStatus = 1;
        } catch (error) {
          this.loading = false;
          Swal.fire({
            icon: 'error',
            title: error.msg,
            showConfirmButton: false,
            timer: 3000,
          });
          this.clearCustomerData();
          this.clearTrackingOrder();
          this.userPhoneStatus = 1;
        }
      }
    },
  },
};
</script>

<style scoped></style>
